@import '~bootstrap/dist/css/bootstrap.min.css';
@import '~react-perfect-scrollbar/dist/css/styles.css';
@import '~c3/c3.min.css';
@import '~react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

@import 'components/kit/vendors/antd/style';
@import 'components/kit/vendors/bootstrap/style';
@import 'components/kit/vendors/perfect-scrollbar/style';
@import 'components/kit/vendors/nprogress/style';
@import 'components/kit/vendors/chartist/style';
@import 'components/kit/vendors/chartist-tooltip-plugin/style';
@import 'components/kit/vendors/jvectormap/style';
@import 'components/kit/vendors/react-sortable-tree/style';
@import 'components/kit/vendors/react-draft-wysiwyg/style';

// Clean UI KIT styles
@import 'components/kit/core/style';
// Clean UI styles
@import 'components/cleanui/styles/style';
// Custom App styles
@import 'components/app/style/custom';

.gift-card-sales h4 {
  justify-content: end !important;
}
.gift-card-sales .ant-form {
  margin-bottom: 10px;
}

.gift-card-redeem h4 {
  justify-content: end !important;
}

.gift-card-redeem .ant-form {
  margin-bottom: 10px;
}

.new-leads h4 {
  justify-content: end !important;
}

.new-leads .ant-form {
  margin-bottom: 10px;
}

.signups h4 {
  justify-content: end !important;
}

.signups .ant-form {
  margin-bottom: 10px;
}

.revenue h4 {
  justify-content: end !important;
}

.revenue .ant-form {
  margin-bottom: 10px;
}
